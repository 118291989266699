@import '../../../RbKit/config';

.dosageContainer {
    align-items: center;
    border-top: solid 1px $colorGrey10;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-top: 1rem;

    h3 {
        margin: 0;
    }
}

.dosage {
    align-items: flex-start;
    display: flex;
    border-top: solid 1px $colorGrey10;
    margin-bottom: .5rem;
    padding-top: .5rem;
    
    > div:first-child {
        flex: 1;
        margin-right: 1rem;
    }

    > button {
        margin-top: 1rem;
    }
}

.hospital {
    align-items: center;
    background-color: $colorWhite;
    border: solid 1px $colorGrey20;
    border-radius: 2px;
    color: $colorGrey90Alpha;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    justify-content: space-between;
    margin: .5rem 0;
    min-height: calc(3.5rem + 2px);
    line-height: 1.25rem;
    padding: 1rem;
    position: relative;
    transition:
        border-color 150ms linear,
        box-shadow 150ms linear;
    width: 100%;

    &:hover {
        border-color: $colorBlue60;
    }
}
